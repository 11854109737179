const contact = {
    title: 'Got a question?',
    name: 'Your name',
    email: 'E-mail',
    message: 'Message',
    send: 'Send',
    siteBy: 'Website by',
}

export default contact
