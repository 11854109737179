const homepage = {
    title: 'Kā dzīvo studenti Latvijā?',
    starterIntro: 'Dalies ar savu pieredzi kopā ar studentiem no 30 valstīm',
    starterTeaser: 'Aptauja ir noslēgusies. Liels paldies visiem, kuri piedalījās!',

    access: 'Ceļš uz studijām',
    accessDescription: 'Uzreiz pēc vidusskolas vai ar pārtraukumu',
    time: 'Laika sadalījums',
    timeDescription: 'Laiks lekcijām un patstavīgajam darbam',
    employment: 'Darbs',
    employmentDescription: 'Pirms, studiju laikā un pēc absolvēšanas',
    budget: 'Ienākumi un izdevumi',
    budgetDescription: 'Ko apmaksā no savas kabatas, ko palīdz segt ģimene',
    conditions: 'Dzīves apstākļi',
    conditionsDescription: 'Mājoklis, aprīkojums, ģimene',
    mobility: 'Starptautiskā mobilitāte',
    mobilityDescription: 'Pieredze apmaiņu programmās vai praksē ārzemēs',

    ctaButton: 'Aiziet!',
    ctaURL: 'https://aptauja.eurostudent.lv/lv?ref=cta',
}

export default homepage
