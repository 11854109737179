const contact = {
    title: 'Есть вопрос?',
    name: 'Имя',
    email: 'Адрес э-почты',
    message: 'Сообщение',
    send: 'Отправить',
    siteBy: 'Дизайн',
}

export default contact
