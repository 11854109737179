const homepage = {
    title: 'What it\'s like to be a student in Latvia?',
    starterIntro: 'Share your experience and opinion together with students from 30 countries',
    starterTeaser: 'The survey is closed. Many thanks to all who participated!',

    access: 'Access to higher education',
    accessDescription: '',
    time: 'Time budget ',
    timeDescription: '',
    employment: 'Employment',
    employmentDescription: '',
    budget: 'Income and expenditure',
    budgetDescription: '',
    conditions: 'Living conditions',
    conditionsDescription: '',
    mobility: 'Foreign mobility',
    mobilityDescription: '',

    ctaButton: 'Let\'s go!',
    ctaURL: 'https://aptauja.eurostudent.lv/en?ref=cta',
}

export default homepage
