const homepage = {
    title: 'Как живут студенты в Латвии?',
    starterIntro: 'Поделись своим опытом, присоединяйся к студентам из 30 стран',
    starterTeaser: 'Опрос подошел к концу. Благодарим всех, кто принял участие!',

    access: 'Путь к высшему образованию',
    accessDescription: '',
    time: 'Распределение времени',
    timeDescription: '',
    employment: 'Работа',
    employmentDescription: '',
    budget: 'Доходы и расходы',
    budgetDescription: '',
    conditions: 'Условия проживания',
    conditionsDescription: '',
    mobility: 'Опыт участия в программах обмена',
    mobilityDescription: '',

    ctaButton: 'Начать',
    ctaURL: 'https://aptauja.eurostudent.lv/ru?ref=cta',
}

export default homepage
