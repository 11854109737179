import homepage from './homepage'
import blog from './blog'
import contact from './contact'
import error404 from './error404'

const en = {
    homepage,
    blog,
    contact,
    error404,
}

export default en
