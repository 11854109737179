const contact = {
    title: 'Ir jautājums?',
    name: 'Tavs vārds',
    email: 'E-pasts',
    message: 'Ziņas teksts',
    send: 'Nosūtīt',
    siteBy: 'Vietnes dizains',
}

export default contact
